import { n as normalizeComponent, F as FasHeader, M as MyMixin } from "./index.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", {
    attrs: {
      "items": _vm.itemsMenu
    }
  }), _c("simple-form", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c("fas-header", {
          attrs: {
            "title": _vm.entity.barcode || "",
            "redirect-route": "/assets",
            "custom-back": _vm.onBack
          },
          scopedSlots: _vm._u([{
            key: "customButton",
            fn: function() {
              return [_c("a-button", {
                attrs: {
                  "type": "primary"
                },
                on: {
                  "click": function($event) {
                    return handleSubmit(_vm.submit);
                  }
                }
              }, [_vm._v(" Save Asset ")])];
            },
            proxy: true
          }], null, true)
        }), _c("div", {
          staticClass: "edit-asset-form px-5 pt-4"
        }, [_c("a-row", {
          attrs: {
            "gutter": [24, 24],
            "type": "flex"
          }
        }, [_c("a-col", {
          attrs: {
            "sm": 24,
            "lg": 12
          }
        }, [_c("a-card", {
          staticClass: "h-100",
          attrs: {
            "head-style": {
              borderBottom: "0px"
            },
            "title": "Asset Details"
          }
        }, [_c("a-row", [_c("a-col", {
          staticClass: "h-100",
          attrs: {
            "span": 24
          }
        }, [_c("a-row", {
          attrs: {
            "gutter": [16, 16]
          }
        }, [_c("a-col", {
          attrs: {
            "span": 24
          }
        }, [_c("text-input", {
          attrs: {
            "label": "Barcode",
            "rules": "required",
            "form-item": "",
            "disabled": true,
            "value": _vm.entity.barcode
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 24
          }
        }, [_c("text-input", {
          attrs: {
            "label": "Captured Date & Time",
            "rules": "required",
            "form-item": "",
            "disabled": true,
            "value": _vm.formatDate(_vm.entity.createdAt)
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 24
          }
        }, [_c("text-input", {
          attrs: {
            "label": "Captured User",
            "rules": "required",
            "form-item": "",
            "disabled": true,
            "value": _vm.entity.createUserName
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 24
          }
        }, [_c("text-input", {
          attrs: {
            "label": "Location",
            "rules": "required",
            "form-item": "",
            "value": _vm.entity.location
          },
          on: {
            "change": function($event) {
              return _vm.storeValue(`location`, $event);
            }
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 12
          }
        }, [_c("text-input", {
          attrs: {
            "label": "Longitude",
            "rules": "required",
            "form-item": "",
            "value": _vm.entity.longitude,
            "disabled": true
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 12
          }
        }, [_c("text-input", {
          attrs: {
            "label": "Latitude",
            "rules": "required",
            "form-item": "",
            "value": _vm.entity.latitude,
            "disabled": true
          }
        })], 1)], 1)], 1), _c("a-col", {
          attrs: {
            "span": 24
          }
        }, [_c("text-area", {
          attrs: {
            "label": "Comments",
            "rows": 5,
            "form-item": "",
            "value": _vm.entity.comment
          },
          on: {
            "change": function($event) {
              return _vm.storeValue(`comment`, $event);
            }
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 24
          }
        }, [_c("p", {
          staticClass: "neutral-10--text"
        }, [_vm._v(" Attachments (Maximum 2 pics) ")]), _c("upload-input", {
          attrs: {
            "form-item": "",
            "type": "path",
            "upload-type": "multiFile",
            "action": _vm.actionUrl,
            "file-list": _vm.fileList,
            "label": "Image",
            "rules": "required",
            "max-file-size": 10,
            "max-list-length": 2,
            "confirm-remove-content": "Are you sure that you want to delete the selected attachment?"
          },
          on: {
            "change": _vm.onUpload
          }
        })], 1), _c("a-col", {
          staticClass: "edit-modal__last-modified pt-2",
          attrs: {
            "span": 24
          }
        }, [_vm._v(" Last modified by " + _vm._s(`${_vm.entity.modifiedUserName}`) + " on " + _vm._s(_vm._f("moment")(_vm.entity.updatedAt, "DD/MM/YYYY LT")) + " ")])], 1)], 1)], 1), _c("a-col", {
          attrs: {
            "sm": 24,
            "lg": 12
          }
        }, [_c("a-card", {
          staticClass: "h-100",
          attrs: {
            "head-style": {
              borderBottom: "0px"
            },
            "title": "Additional Informations"
          }
        }, [_c("a-row", {
          attrs: {
            "gutter": 16
          }
        }, [_c("a-col", {
          attrs: {
            "span": 24
          }
        }, [_c("text-input", {
          attrs: {
            "label": "Description",
            "disabled": true,
            "form-item": "",
            "value": _vm.additionalAssetDetail ? _vm.additionalAssetDetail.description ? _vm.additionalAssetDetail.description : "No details in BPCS found" : ""
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 24
          }
        }, [_c("text-input", {
          attrs: {
            "label": "Last Count Location",
            "disabled": true,
            "form-item": "",
            "value": _vm.additionalAssetDetail ? _vm.additionalAssetDetail.lastCountLocation ? _vm.additionalAssetDetail.lastCountLocation : "No details in BPCS found" : ""
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 24
          }
        }, [_c("text-input", {
          attrs: {
            "label": "Installation Date",
            "disabled": true,
            "form-item": "",
            "value": _vm.additionalAssetDetail ? _vm.additionalAssetDetail.installationDate ? _vm.$moment(_vm.additionalAssetDetail.installationDate).format("DD/MM/YYYY") : "No details in BPCS found" : ""
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 24
          }
        }, [_c("text-input", {
          attrs: {
            "label": "Original Cost",
            "disabled": true,
            "form-item": "",
            "value": _vm.additionalAssetDetail ? _vm.additionalAssetDetail.originalCost ? _vm.additionalAssetDetail.originalCost : "No details in BPCS found" : ""
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 24
          }
        }, [_c("text-input", {
          attrs: {
            "label": "Net Book Value",
            "disabled": true,
            "form-item": "",
            "value": _vm.additionalAssetDetail ? _vm.additionalAssetDetail.netBookValue ? _vm.additionalAssetDetail.netBookValue : "No details in BPCS found" : ""
          }
        })], 1)], 1)], 1)], 1)], 1)], 1)];
      }
    }])
  })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "EditAsset",
  components: { FasHeader },
  inject: ["crud", "apiUrl"],
  mixins: [MyMixin],
  data() {
    const { id, status, fullName } = this.$route.query;
    return {
      FasHeader,
      editForm: {},
      isLoading: false,
      actionUrl: this.apiUrl + "/files/images",
      previewVisible: false,
      previewImage: "",
      fileList: [],
      facilityInfo: {},
      additionalAssetDetail: {},
      itemsMenu: [
        {
          key: "fixedAssetRegister",
          title: "Fixed Asset Register",
          path: "/fixed-asset-register"
        },
        {
          key: "fixedAssetRegister",
          title: this.$route.query.fullName,
          path: `/assets?id=${id}&status=${status}&fullName=${fullName}`
        },
        {
          key: "asset",
          title: this.$route.query.barcode,
          path: ""
        }
      ]
    };
  },
  computed: {
    entity() {
      return this.crud.getEntity() || [];
    }
  },
  watch: {
    entity(newVal) {
      if (newVal.attachments.length > 0) {
        this.fileList = newVal.attachments.map((item) => {
          item["path"];
          return {
            ...item,
            uid: item.id,
            url: this.apiUrl + item.path,
            response: { path: item.path }
          };
        });
      }
    }
  },
  async created() {
    this.storeValues(this.entity);
  },
  async mounted() {
    this.visible = true;
    await this.crud.fetchEntity();
    await this.getFacilityInfo();
    await this.getAdditionalAssetDetail(this.$route.query.barcode);
  },
  methods: {
    onBack() {
      this.pushToRouteWithQuery("/assets");
    },
    onUpload(res) {
      this.storeValue("attachments", res);
    },
    async getFacilityInfo() {
      const { data } = await this.axios.get(`${this.apiUrl}/fas/assetregisters/${this.$route.query.id}`);
      this.facilityInfo = data;
    },
    async getAdditionalAssetDetail(barcode) {
      if (!barcode) {
        this.additionalAssetDetail = void 0;
        return;
      }
      await this.axios.get(`${this.apiUrl}/fas/barcoderegisters/barcode`, {
        params: {
          barcode,
          year: this.facilityInfo.year,
          countryCode: this.facilityInfo.countryCode
        }
      }).then((res) => {
        this.additionalAssetDetail = res.data;
      }).catch((err) => {
        console.log(err);
      });
    },
    storeValue(resourceKey, value) {
      const editForm = this.editForm;
      this.editForm = {
        ...editForm,
        [resourceKey]: value
      };
      this.crud.updateEntity("editForm", {
        resourceKey,
        value
      });
    },
    storeValues(entity) {
      if (entity) {
        Object.entries(this.entity).forEach(([key, value]) => this.storeValue(key, value));
      }
    },
    async submit() {
      this.isLoading = true;
      await this.crud.submitEntity("replace").then(async () => {
        this.createForm = {};
        this.isLoading = false;
        await this.crud.fetchList();
        this.visible = false;
      }).catch(() => this.isLoading = false);
    },
    cancel() {
      this.visible = false;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditAsset = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", {
    attrs: {
      "name": "fas.assets",
      "api-url": _vm.apiUrl,
      "redirect-route": "/assets"
    }
  }, [_c("edit-asset")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: { EditAsset },
  data() {
    return {
      EditAsset,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
